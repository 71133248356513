import React from 'react';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Staff } from './../components/Staff';

interface StaffBioProps {
  profile?: Staff;
  staffFallbackImage: any;
  onClose: any;
}

const StaffBio: React.FC<StaffBioProps> = props => {
  return (
    <section className="bg-primary p-8 h-auto fixed bottom-0 left-0 right-0">
      <div className="flex flex-col sm:flex-row w-full md:w-2/3 mx-auto p-2 sm:p-10">
        <div className="w-full md:w-1/3">
          <img
            className="w-1/2 sm:w-full mx-auto rounded-xl shadow-lg"
            src={props.profile?.photo?.file?.url ?? props.staffFallbackImage}
          />
        </div>
        <div className="w-auto md:w-2/3 ml-6 mt-6 sm:mt-0">
          <div className="flex flex-row justify-between">
            <h2 className="text-5xl sm:text-6xl serif">
              <span className="text-blue-200">{props.profile?.firstName}</span>{' '}
              <span className="text-gray-300">{props.profile?.lastName}</span>
            </h2>
            <button className="text-3xl my-auto" onClick={props.onClose}>
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="text-blue-200 hover:text-white cursor-pointer"
              />
            </button>
          </div>
          <div className="mt-2">
            <h3 className="text-gray-200 text-xl">{props.profile?.title}</h3>
            <p className="text-blue-200 text-lg sm:text-xl sans-serif mt-6">
              {props.profile?.bio.bio}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StaffBio;
