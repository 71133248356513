import { graphql, useStaticQuery } from 'gatsby';
import groupBy from 'lodash.groupby';
import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import ContentContainer from '../components/common/ContentContainer';
import Layout from '../components/Layout';
import SearchInput from '../components/SearchInput';
import { Staff } from './../components/Staff';
import StaffBio from './../components/StaffBio';
import SEO from '../components/Seo';

interface StaffCardProps {
  staffPerson: Staff;
  staffFallbackImage: any;
  setSelectedStaff: any;
}
const StaffCard: React.FC<StaffCardProps> = props => {
  return (
    <div className="bg-white shadow-md rounded-lg mb-8 h-full">
      <div className="p-6">
        <h3 className="text-2xl text-primary">{props.staffPerson.firstName}</h3>
        <h3 className="text-2xl text-gray-500">{props.staffPerson.lastName}</h3>
        <p className="mt-4 text-primary sans-serif h-16">
          {props.staffPerson.title}
        </p>
      </div>
      <div className="h-64 overflow-hidden bg-gray-700">
        <img
          className="object-cover"
          src={
            props.staffPerson.photo?.file?.url ??
            props.staffFallbackImage.childImageSharp.sizes.src
          }
          alt={`${props.staffPerson.firstName} ${props.staffPerson.lastName} photo`}
        />
      </div>
      <div className="px-6 mt-6">
        <button
          onClick={() => props.setSelectedStaff(props.staffPerson)}
          className="text-primary hover:underline"
        >
          Read Bio &rarr;
        </button>
      </div>
    </div>
  );
};

interface StaffSectionProps {
  staff: Staff[];
  staffFallbackImage: any;
  setSelectedStaff: any;
}

const groupStaffByDepartment = (staff: Staff[]) => {
  const grouped = groupBy(staff, 'category');
  let seniorAndCentral: any = [];
  if (grouped['Senior Leaders']) {
    seniorAndCentral = [...seniorAndCentral, ...grouped['Senior Leaders']];
  }
  if (grouped['Central Office Staff']) {
    seniorAndCentral = [
      ...seniorAndCentral,
      ...grouped['Central Office Staff']
    ];
  }
  return {
    'Senior Staff and Central Office Team': seniorAndCentral || [],
    'Staff Developers': grouped['Staff Developers'] || []
    // 'Central Office Staff': grouped['Central Office Staff'] || []
  };
};

const StaffSection: React.FC<StaffSectionProps> = props => {
  const [visibleStaff, setVisibleStaff] = useState(
    groupStaffByDepartment(props.staff)
  );
  const [searchTerm, setSearchTerm] = useState(null);
  const debounced = useDebouncedCallback(
    staff => setVisibleStaff(groupStaffByDepartment(staff)),
    300
  );
  return (
    <section className="w-5/6 m-auto">
      <div className="mb-6">
        <div className="flex flex-col">
          <h2 className="text-primary text-3xl font-bold mb-4">Our People</h2>

          <div className="sm:w-full lg:w-1/3">
            <SearchInput
              onChange={(e: any) => {
                if (!e.target.value) {
                  setSearchTerm(null);
                  return debounced.callback(props.staff);
                }
                setSearchTerm(e.target.value);
                const term = new RegExp(e.target.value, 'gi');
                debounced.callback(
                  props.staff.filter((s: Staff) => {
                    return (
                      `${s.firstName} ${s.lastName}`.match(term) ||
                      s.firstName.match(term) ||
                      s.lastName.match(term) ||
                      s.title.match(term)
                    );
                  })
                );
              }}
              placeholder="Search staff"
            />
          </div>
        </div>
        <div className="mt-4">
          {searchTerm && (
            <p className="italic text-gray-500">
              Showing results for filter "{searchTerm}"
            </p>
          )}
        </div>
      </div>
      <div className="flex flex-col px-12 lg:px-0">
        {Object.keys(visibleStaff).map((category: string, i: number) => (
          <div className="" key={i}>
            <h3 className="text-primary text-2xl font-bold my-6" key={i}>
              {category}
            </h3>
            <div className="grid grid-col-1 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-1 sm:gap-8 m-auto">
              {visibleStaff[category]
                .sort((a: Staff, b: Staff) => a.sortOrder - b.sortOrder)
                .map((s: Staff, i: number) => (
                  <div key={i} className="mb-4 lg:mb-0">
                    <StaffCard
                      staffPerson={s}
                      staffFallbackImage={props.staffFallbackImage}
                      setSelectedStaff={props.setSelectedStaff}
                    />
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

const OurPeople: React.FC = props => {
  const [selectedStaff, setSelectedStaff] = useState();
  const data = useStaticQuery(graphql`
    {
      staffFallbackImage: file(
        relativePath: { eq: "images/staff-fallback.png" }
      ) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      staff: allContentfulStaff(
        sort: { fields: lastName, order: ASC }
        filter: { node_locale: { eq: "en-US" } }
      ) {
        edges {
          node {
            id
            firstName
            lastName
            title
            category
            sortOrder
            bio {
              bio
            }
            photo {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);
  const staff = data.staff.edges.map((e: any) => e.node);
  return (
    <Layout>
      <SEO title="Our People" />
      <ContentContainer>
        <StaffSection
          staff={staff}
          staffFallbackImage={data.staffFallbackImage}
          setSelectedStaff={setSelectedStaff}
        />
      </ContentContainer>
      {selectedStaff && (
        <div className="relative">
          <StaffBio
            profile={selectedStaff}
            staffFallbackImage={
              data.staffFallbackImage.childImageSharp.sizes.src
            }
            onClose={() => setSelectedStaff(null)}
          />
        </div>
      )}
    </Layout>
  );
};

export default OurPeople;
